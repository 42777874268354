angular.module("app")
    .factory("_authInterceptor", function($q, $location, _appStorage) {
        function rejectToken() {
            _appStorage.remove('token');

            if (window.location.href.indexOf("/auth/login.p") < 0) {
                window.location.href = '/auth/login.p/';
            }
        }

        function makeid(len) {
            var text = "";
            var possible = "abcdefghijklmnopqrstuvwxyz0123456789";

            for( var i=0; i < len; i++ )
                text += possible.charAt(Math.floor(Math.random() * possible.length));

            return text;
        }

        return {
            request: function (req) {
                var token = _appStorage.get('token'),
                    token_remember = _appStorage.get('token_remember');

                var params = $location.search();

                if (params['access_token']) {
                    _appStorage.set('token', params['access_token']);
                    _appStorage.set('admin_url', params['admin_url']);

                } else if(token) {
                    req.headers["Authorization"] = 'Bearer ' + token;

                    if (!token_remember) {
                        var token_time = _appStorage.get('token_time'),
                            expires_in = moment(token_time).add(2, 'hours'),
                            expired = expires_in.isBefore(moment());

                        if (expired) {
                            rejectToken();

                            return $q.reject(req);
                        }
                    }
                }

                var id = _appStorage.get('X-Device-ID');
                if (_.isNull(id) || _.isUndefined(id) || id == '') {
                    id = makeid(32);
                    _appStorage.set('X-Device-ID', id);
                }
                req.headers["X-Device-ID"] = id;

                return req;
            },
            response: function(response) {
                _appStorage.set('token_time', moment().valueOf());

                return response || $q.when(response);
            },
            responseError: function(rejection) {
                var status = rejection.status;

                if (status == 401 || status == 403) {
                    rejectToken();
                }

                return $q.reject(rejection);
            }
        };
    })
    .config(function ($httpProvider) {
        //this should be before nga configuration
        $httpProvider.interceptors.push("_authInterceptor");
    });